import React, { useMemo } from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faFileArrowDown } from '@fortawesome/free-solid-svg-icons'
import Layout from '/src/components/layout'
import { SendEnquiryBlock } from '/src/components/common'

import './technical_resources.scss'

export const Head = ({ data }) => {
  return <title>Product & Technical Resources | Metalcorp</title>
}

const ResourceDownload = ({ relationships }) => {
  const { field_resource_file } = relationships
  if (!field_resource_file || !field_resource_file.length) return <></>

  return <a className="download-button button primary" target="_blank" href={field_resource_file[0].uri.url}>
    Download
    <FontAwesomeIcon icon={faFileArrowDown} />
  </a>
}

const ResourceListItem = ({ title, path, body, relationships }) => {
  /*const { field_resource_cover } = relationships
      {field_resource_cover && (
        <GatsbyImage alt={title} image={field_resource_cover.gatsbyImage} />
      )}
  */

  return (
    <label className="technical-list-item">
      <div className="title-container mb-3">
        <h3 className="title">{title}</h3>
        <ResourceDownload relationships={relationships} />
      </div>
    </label>
  )
}

const ResourceList = ({ items }) => {
  return (
    <div className="technical-list">
      <Row>
        {items.map((item, index) => {
          return (
            <Col key={index} className="mb-4" sm={4}>
              <ResourceListItem {...item.node} />
            </Col>
          )
        })}
      </Row>
    </div>
  )
}

export default ({ data }) => {
  const resources = data.allNodeResources.edges

  return (
    <Layout>
      <Row className="mt-5 mb-5">
        <Col xs={12}>
          <div className="mb-5">
            <Link className="back-to-link" to='/resources'>
              <FontAwesomeIcon icon={faArrowLeft} />
              Back to resources
            </Link>
          </div>
          <ResourceList items={resources} />
        </Col>
      </Row>
      <Row className="mb-5">
        <Col xs={12}>
          <SendEnquiryBlock />
        </Col>
      </Row>
    </Layout>
  )
}

export const query = graphql`
  query {
    allNodeResources(
      filter: {relationships: {field_resource_category: {elemMatch: {name: {eq: "Technical and Weld Zone Resources"}}}}}
      sort: {fields: created, order: DESC}
    ) {
      edges {
        node {
          title
          body {
            processed
          }
          path {
            alias
          }
          relationships {
            node__product {
              title
              path {
                alias
              }
            }
            field_resource_file {
              uri {
                url
              }
            }
            field_resource_category {
              name
            }
          }
        }
      }
    }
  }
`

/*export const query = graphql`
  query {
    allNodeResources(
      filter: {relationships: {field_resource_category: {elemMatch: {name: {eq: "Technical Resources"}}}}}
      sort: {fields: created, order: DESC}
    ) {
      edges {
        node {
          body {
            processed
          }
          path {
            alias
          }
          title
          relationships {
            node__product {
              title
              path {
                alias
              }
            }
            field_resource_file {
              publicUrl
            }
            field_resource_cover {
              gatsbyImage(
                fit: COVER
                formats: AUTO
                layout: FULL_WIDTH
                quality: 100
                placeholder: BLURRED
                width: 315
                height: 225
              )
            }
            field_resource_category {
              name
            }
          }
        }
      }
    }
  }
`*/
